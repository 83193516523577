/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSelect from '../../../components/useSelect';
import { appConfig } from '../../../config/AppConfig';
import { FilterContext, GROSS_REVENUE_CROSS_LOCATION_ID } from '../../../contexts/FilterContext';
import { LanguageContext } from '../../../contexts/useLanguage';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { ReportType } from '../../../models/ReportType';
import { DrawerContext } from '../../dashboardLayout/useDrawer';

export interface State {
    reportType: ReportType;
    tabIndex: string;
    exportType: ExportType;
    isExportLoading: boolean;
}
export enum ExportType {
    none = 'none',
    pdf = 'pdf',
    excel = 'excel',
}

export const DEFAULT_TAB_INDEX = 'graph';
export const DEFAULT_REPORT_TYPE = ReportType.dailyVisit;

export default function useReport() {
    const { type, index } = useParams();
    const { t } = useContext(LanguageContext);
    const [state, setState] = useState<State>({
        reportType: type as ReportType,
        tabIndex: index as string,
        exportType: ExportType.none,
        isExportLoading: false,
    });
    const { setActiveItem } = useContext(DrawerContext);
    useScrollToTop();

    const {
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        capacityAnalysis,
        repeatVisits,
        groupByPNCType,
        groupByPCNCancellations,
        groupPCNByReason,
        groupPCNByZone,
        setReportState,
        averageLengthOfStay,
        reportState,
        grossRevenueStatistic,
        grossRevenueByTypeStatistic,
        grossRevenueByLTStatistic,
        filterState,
    } = useContext(FilterContext);

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectReports: ReportTypeItem[] =
        appConfig.flagFeatures.isEnableReportPCNs === true
            ? [
                  {
                      value: 1,
                      label: t('dashboard:title.dailyVisit'),
                      url: ReportType.dailyVisit,
                  },
                  {
                      value: 2,
                      label: t('dashboard:title.capacityAnalysis'),
                      url: ReportType.capacityAnalysis,
                  },
                  {
                      value: 3,
                      label: t('dashboard:title.averageLengthOfVisits'),
                      url: ReportType.averageLength,
                  },
                  {
                      value: 4,
                      label: t('dashboard:title.repeatVisits'),
                      url: ReportType.repeatVisits,
                  },
                  {
                      value: 9,
                      label: t('dashboard:text.dailyEVVisits'),
                      url: ReportType.evDailyVisit,
                  },
                  {
                      value: 10,
                      label: t('dashboard:text.averageLengthOfEVVisits'),
                      url: ReportType.evAverageLength,
                  },
                  {
                      value: 5,
                      label: t('dashboard:title.groupByPCNTypes'),
                      url: ReportType.groupByPCNTypes,
                  },
                  {
                      value: 6,
                      label: t('dashboard:title.groupByPCNCancellations'),
                      url: ReportType.groupByPCNCancellations,
                  },
                  {
                      value: 7,
                      label: t('dashboard:title.pcnByZones'),
                      url: ReportType.PCNByZones,
                  },
                  {
                      value: 8,
                      label: t('dashboard:title.PCNByReason'),
                      url: ReportType.PCNByReason,
                  },
              ]
            : [
                  {
                      value: 1,
                      label: t('dashboard:title.dailyVisit'),
                      url: ReportType.dailyVisit,
                  },
                  {
                      value: 2,
                      label: t('dashboard:title.capacityAnalysis'),
                      url: ReportType.capacityAnalysis,
                  },
                  {
                      value: 3,
                      label: t('dashboard:title.averageLengthOfVisits'),
                      url: ReportType.averageLength,
                  },
                  {
                      value: 4,
                      label: t('dashboard:title.repeatVisits'),
                      url: ReportType.repeatVisits,
                  },
                  {
                      value: 9,
                      label: t('dashboard:text.dailyEVVisits'),
                      url: ReportType.evDailyVisit,
                  },
                  {
                      value: 10,
                      label: t('dashboard:text.averageLengthOfEVVisits'),
                      url: ReportType.evAverageLength,
                  },
              ];

    const grossRevenueBySTs: ReportTypeItem = {
        value: 11,
        label: t('dashboard:title.grossRevenueSTs'),
        url: ReportType.grossRevenueBySTs,
    };

    const grossRevenueByTypeItem: ReportTypeItem = {
        value: 12,
        label: t('dashboard:title.ActiveLongTermBooking'),
        url: ReportType.activeLongTermBooking,
    };

    const grossRevenueByLTs: ReportTypeItem = {
        value: 13,
        label: t('dashboard:title.grossRevenueLTs'),
        url: ReportType.grossRevenueByLTs,
    };

    if (filterState.locationId === GROSS_REVENUE_CROSS_LOCATION_ID) {
        selectReports.push(grossRevenueBySTs);
        selectReports.push(grossRevenueByTypeItem);
        selectReports.push(grossRevenueByLTs);
    }
    const [reportTypes, setReportTypes] = useState<ReportTypeItem[]>(selectReports);

    const addGrossRevenueToReports = (reports: ReportTypeItem[]) => [
        ...reports,
        grossRevenueBySTs,
        grossRevenueByTypeItem,
        grossRevenueByLTs,
    ];

    useEffect(() => {
        if (filterState.locationId === GROSS_REVENUE_CROSS_LOCATION_ID) {
            const find = reportTypes.find((item) => item.value === grossRevenueBySTs.value);
            const findByType = reportTypes.find((item) => item.value === grossRevenueByTypeItem.value);
            const findByLTs = reportTypes.find((item) => item.value === grossRevenueByLTs.value);
            (!findByType || !find || !findByLTs) && setReportTypes(addGrossRevenueToReports(reportTypes));
        } else {
            //when old report type is grossRevenue set to default
            if (
                [ReportType.grossRevenueBySTs, ReportType.activeLongTermBooking, ReportType.grossRevenueByLTs].includes(
                    reportState.report
                )
            ) {
                setState((prev) => ({ ...prev, reportType: ReportType.dailyVisit, tabIndex: 'graph' }));
                setReportState((prev) => ({ ...prev, showingPage: 'report', report: ReportType.dailyVisit }));
            }

            const arr = reportTypes.filter(
                (item) =>
                    ![grossRevenueBySTs.value, grossRevenueByTypeItem.value, grossRevenueByLTs.value].includes(
                        item.value
                    )
            );
            setReportTypes(arr);
        }
    }, [filterState.locationId]);

    const selectReport = useSelect({
        list: reportTypes,
        label: t('companies:text.type'),
        value: state.reportType,
        setFilter: (val) => {
            const reportValue = reportTypes.find((item) => item.value === val);
            //set reportType to filter
            setReportState((prev) => ({ ...prev, showingPage: 'report', report: reportValue?.url! }));
            setState((prev) => ({ ...prev, reportType: reportValue?.url!, tabIndex: index! }));
        },
    });

    const onSelectReportType = (exportType: ExportType) => {
        setState((prev) => ({ ...prev, exportType: exportType, isExportLoading: true }));
        handleClose();
    };

    useEffect(() => {
        setActiveItem(3);
        if (type && index) {
            const report = reportTypes.find((item) => item.url == type);
            if (report) {
                if (index !== 'graph' && index !== 'details') {
                    navigate(`/`, { replace: true });
                } else {
                    //setPage and filter to filter
                    setReportState((prev) => ({ ...prev, showingPage: 'report', report: report?.url! }));
                    setState((prev) => ({ ...prev, reportType: report?.url!, tabIndex: index }));
                }
            } else {
                navigate(`/`, { replace: true });
            }
        } else {
            navigate(`/`, { replace: true });
        }
    }, [type, index]);

    useEffect(() => {
        if (
            [ReportType.activeLongTermBooking, ReportType.grossRevenueBySTs, ReportType.grossRevenueByLTs].includes(
                type as ReportType
            )
        ) {
            if (!filterState.locationId) {
                navigate('/', { replace: true });
            }
        } else {
            navigate(`/reports/${state.reportType}/${state.tabIndex}`);
        }
    }, [state.reportType, state.tabIndex]);

    return {
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        averageLengthOfStay,
        capacityAnalysis,
        repeatVisits,
        groupByPNCType,
        groupByPCNCancellations,
        groupPCNByReason,
        groupPCNByZone,
        setReportState,
        onSelectReportType,
        selectReport,
        open,
        handleClick,
        anchorEl,
        handleClose,
        setState,
        grossRevenueStatistic,
        grossRevenueByTypeStatistic,
        grossRevenueByLTStatistic,
        state,
        filterState,
    };
}

export const ReportContext = React.createContext<ReturnType<typeof useReport>>({} as any);

export interface ReportTypeItem {
    value: number;
    label: string;
    url: ReportType;
}
