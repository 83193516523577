/* eslint-disable eqeqeq */
import { useContext, useEffect } from 'react';
import ChartIcon from '../../assets/images/ChartIcon';
import DetailIcon from '../../assets/images/DetailIcon';
import { LanguageContext } from '../../contexts/useLanguage';
import { capitalizeFirstLetter } from '../../helpers/UppercaseFirstLetter';
import { ChartShowType } from '../../models/ChartShowType';
import { ReportType } from '../../models/ReportType';
import ActiveLTBooking from '../dashboard/components/ActiveLTBooking';
import AverageLengthOfVisits from '../dashboard/components/AverageLengthOfVisits';
import CapacityAnalysisChart from '../dashboard/components/CapacityAnalysisChart';
import DailyVisits from '../dashboard/components/DailyVisits';
import EVDailyVisits from '../dashboard/components/EVDailyVisits';
import EVLengthOfVisits from '../dashboard/components/EVLengthOfVisits';
import GrossRevenueByLTs from '../dashboard/components/GrossRevenueByLTs';
import GrossRevenueChart from '../dashboard/components/GrossRevenueChart';
import GroupPCNByReason from '../dashboard/components/GroupPCNByReason';
import PCNByZones from '../dashboard/components/PCNByZones';
import PCNCancellationsChart from '../dashboard/components/PCNCancellationsChart';
import PCNTypesChar from '../dashboard/components/PCNTypesChar';
import RepeatVisits from '../dashboard/components/RepeatVisits';
import MyTable from '../MyTable';
import TablePCNByReasons from './components/TablePCNByReasons';
import TablePCNByZones from './components/TablePCNByZones';
import TablePCNCancellations from './components/TablePCNCancellations';
import TablePCNTypes from './components/TablePCNTypes';
import TableRowsActiveLongTerm from './components/TableRowsActiveLongTerm';
import TableRowsActiveLTs from './components/TableRowsActiveLTs';
import TableRowsAverageLength from './components/TableRowsAverageLength';
import TableRowsCapacityAnalysis from './components/TableRowsCapacityAnalysis';
import TableRowsDailyEVVisits from './components/TableRowsDailyEVVisits ';
import TableRowsDailyVisits from './components/TableRowsDailyVisits';
import TableRowsEVAverageLength from './components/TableRowsEVAverageLength';
import TableRowsGrossRevenue from './components/TableRowsGrossRevenue';
import TableRowsRepeatVisit from './components/TableRowsRepeatVisit';
import useExportMedia from './hooks/useExportMedia';
import { ExportType, ReportContext } from './hooks/useReport';
export default function useTabData() {
    const {
        setState,
        dailyVisitsStatistic,
        averageLengthStatistic,
        averageLengthOfStay,
        capacityAnalysis,
        groupByPCNCancellations,
        groupByPNCType,
        groupPCNByReason,
        groupPCNByZone,
        repeatVisits,
        grossRevenueStatistic,
        grossRevenueByTypeStatistic,
        grossRevenueByLTStatistic,
        state,
    } = useContext(ReportContext);

    const { t } = useContext(LanguageContext);
    const {
        exportExcelDailyVisits,
        exportExcelDownloadAverageLengthOfVisits,
        exportExcelDownloadCapacityAnalysis,
        exportExcelDownloadRepeatVisit,
        exportExcelDownloadGroupCancelledPCNByActor,
        exportExcelDownloadGroupPCNByStatus,
        exportExcelDownloadGroupPCNByReason,
        exportExcelDownloadGroupPCNByZone,
        exportExcelDownloadEVDailyVisits,
        exportExcelDownloadEVAverageLength,
        exportExcelDownloadGrossRevenue,
        exportExcelDownloadGrossRevenueByLongTerm,
        exportExcelDownloadGrossRevenueByLts,
    } = useExportMedia();

    useEffect(() => {
        if (state.exportType !== ExportType.none) {
            exportMedia(state.exportType);
        }
    }, [state.exportType]);

    const exportMedia = async (exportType: ExportType) => {
        if (exportType == ExportType.pdf) {
            if (state.reportType == ReportType.dailyVisit) {
                exportExcelDailyVisits(true);
            } else if (state.reportType == ReportType.capacityAnalysis) {
                exportExcelDownloadCapacityAnalysis(true);
            } else if (state.reportType == ReportType.averageLength) {
                exportExcelDownloadAverageLengthOfVisits(true);
            } else if (state.reportType == ReportType.repeatVisits) {
                exportExcelDownloadRepeatVisit(true);
            } else if (state.reportType == ReportType.groupByPCNTypes) {
                exportExcelDownloadGroupPCNByStatus(true);
            } else if (state.reportType == ReportType.groupByPCNCancellations) {
                exportExcelDownloadGroupCancelledPCNByActor(true);
            } else if (state.reportType == ReportType.PCNByZones) {
                exportExcelDownloadGroupPCNByZone(true);
            } else if (state.reportType == ReportType.PCNByReason) {
                exportExcelDownloadGroupPCNByReason(true);
            } else if (state.reportType == ReportType.evDailyVisit) {
                exportExcelDownloadEVDailyVisits(true);
            } else if (state.reportType == ReportType.evAverageLength) {
                exportExcelDownloadEVAverageLength(true);
            } else if (state.reportType == ReportType.grossRevenueBySTs) {
                exportExcelDownloadGrossRevenue(true);
            } else if (state.reportType == ReportType.activeLongTermBooking) {
                exportExcelDownloadGrossRevenueByLongTerm(true);
            } else if (state.reportType == ReportType.grossRevenueByLTs) {
                exportExcelDownloadGrossRevenueByLts(true);
            }
        } else if (exportType == ExportType.excel) {
            if (state.reportType == ReportType.dailyVisit) {
                exportExcelDailyVisits(false);
            } else if (state.reportType == ReportType.capacityAnalysis) {
                exportExcelDownloadCapacityAnalysis(false);
            } else if (state.reportType == ReportType.averageLength) {
                exportExcelDownloadAverageLengthOfVisits(false);
            } else if (state.reportType == ReportType.repeatVisits) {
                exportExcelDownloadRepeatVisit(false);
            } else if (state.reportType == ReportType.groupByPCNTypes) {
                exportExcelDownloadGroupPCNByStatus(false);
            } else if (state.reportType == ReportType.PCNByZones) {
                exportExcelDownloadGroupPCNByZone(false);
            } else if (state.reportType == ReportType.PCNByReason) {
                exportExcelDownloadGroupPCNByReason(false);
            } else if (state.reportType == ReportType.groupByPCNCancellations) {
                exportExcelDownloadGroupCancelledPCNByActor(false);
            } else if (state.reportType == ReportType.evDailyVisit) {
                exportExcelDownloadEVDailyVisits(false);
            } else if (state.reportType == ReportType.grossRevenueBySTs) {
                exportExcelDownloadGrossRevenue(false);
            } else if (state.reportType == ReportType.activeLongTermBooking) {
                exportExcelDownloadGrossRevenueByLongTerm(false);
            } else if (state.reportType == ReportType.grossRevenueByLTs) {
                exportExcelDownloadGrossRevenueByLts(false);
            }
        }
        setState((prev) => ({ ...prev, exportType: ExportType.none }));
    };

    const getPanel = (chart: ReportType) => {
        if (chart == ReportType.dailyVisit) {
            return (
                <DailyVisits
                    chartShowType={ChartShowType.report}
                    dailyVisitsStatistic={dailyVisitsStatistic}
                    minHeightChart={'300px'}
                />
            );
        } else if (chart == ReportType.averageLength) {
            return (
                <AverageLengthOfVisits
                    averageLengthStatistic={averageLengthStatistic}
                    averageLengthOfStay={averageLengthOfStay}
                    chartShowType={ChartShowType.report}
                />
            );
        } else if (chart == ReportType.capacityAnalysis) {
            return <CapacityAnalysisChart capacityAnalysis={capacityAnalysis} chartShowType={ChartShowType.report} />;
        } else if (chart == ReportType.repeatVisits) {
            return <RepeatVisits repeatVisits={repeatVisits} chartShowType={ChartShowType.report} />;
        } else if (chart == ReportType.groupByPCNTypes) {
            return (
                <PCNTypesChar
                    chartShowType={ChartShowType.report}
                    statisticItems={groupByPNCType}
                    reportType={ReportType.groupByPCNTypes}
                />
            );
        } else if (chart == ReportType.groupByPCNCancellations) {
            return (
                <PCNCancellationsChart
                    key={'7'}
                    statisticItems={groupByPCNCancellations}
                    reportType={ReportType.groupByPCNCancellations}
                    chartShowType={ChartShowType.report}
                />
            );
        } else if (chart == ReportType.PCNByZones) {
            return (
                <PCNByZones
                    reportType={ReportType.PCNByZones}
                    groupPCNByZone={groupPCNByZone}
                    chartShowType={ChartShowType.report}
                />
            );
        } else if (chart == ReportType.PCNByReason) {
            return (
                <GroupPCNByReason
                    reportType={ReportType.PCNByReason}
                    groupByReason={groupPCNByReason}
                    chartShowType={ChartShowType.report}
                />
            );
        } else if (chart == ReportType.evDailyVisit) {
            return (
                <EVDailyVisits
                    reportType={ReportType.evDailyVisit}
                    chartShowType={ChartShowType.report}
                    dailyVisitsStatistic={dailyVisitsStatistic}
                />
            );
        } else if (chart == ReportType.evAverageLength) {
            return <EVLengthOfVisits capacityAnalysis={capacityAnalysis} chartShowType={ChartShowType.report} />;
        } else if (chart == ReportType.grossRevenueBySTs) {
            return (
                <GrossRevenueChart
                    chartShowType={ChartShowType.report}
                    grossRevenueStatistic={grossRevenueStatistic}
                    minHeightChart={'300px'}
                />
            );
        } else if (chart == ReportType.activeLongTermBooking) {
            return (
                <ActiveLTBooking
                    chartShowType={ChartShowType.report}
                    minHeightChart={'300px'}
                    grossRevenueStatistic={grossRevenueByTypeStatistic}
                />
            );
        } else if (chart == ReportType.grossRevenueByLTs) {
            return (
                <GrossRevenueByLTs
                    chartShowType={ChartShowType.report}
                    dailyStatistic={grossRevenueByLTStatistic}
                    minHeightChart="300px"
                />
            );
        }
    };

    const getTableRows = (chart: ReportType) => {
        if (chart == ReportType.dailyVisit) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },

                        {
                            label: t('dashboard:text.diesel'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.petrol'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.ev'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.hybrid'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.others'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:text.total'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.dailyVisit}
                    tableRow={<TableRowsDailyVisits key={2} dailyVisitsStatistic={dailyVisitsStatistic} />}
                />
            );
        } else if (chart == ReportType.averageLength) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: capitalizeFirstLetter(t('reportPage:text.hours')),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:title.percentage'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.averageLength}
                    tableRow={<TableRowsAverageLength key={4} averageLengthStatistic={averageLengthStatistic} />}
                />
            );
        } else if (chart == ReportType.capacityAnalysis) {
            return (
                // headers: [[t('reportPage:title.Date'), t('dashboard:text.0to8h'),t('dashboard:text.8to16h'),t('dashboard:text.16to24h')]],
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.0to8h'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.8to16h'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.16to24h'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.capacityAnalysis}
                    tableRow={<TableRowsCapacityAnalysis key={5} capacityAnalysis={capacityAnalysis} />}
                />
            );
        } else if (chart == ReportType.repeatVisits) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.visitTimes'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:title.vehicleCount'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:title.percentage'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.repeatVisits}
                    tableRow={<TableRowsRepeatVisit key={9} repeatVisits={repeatVisits} />}
                />
            );
        } else if (chart == ReportType.groupByPCNTypes) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.status'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:title.count'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.groupByPCNTypes}
                    tableRow={<TablePCNTypes key={10} dataPCNTypes={groupByPNCType} />}
                />
            );
        } else if (chart == ReportType.groupByPCNCancellations) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.cancelledBy'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:title.count'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.groupByPCNCancellations}
                    tableRow={<TablePCNCancellations key={1178} dataPCNCancellations={groupByPCNCancellations} />}
                />
            );
        } else if (chart == ReportType.PCNByZones) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:text.zonesName'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:text.noOfCharges'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:text.serviceTypes'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.PCNByZones}
                    tableRow={<TablePCNByZones key={10} dataPCNByZones={groupPCNByZone} />}
                />
            );
        } else if (chart == ReportType.PCNByReason) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('dashboard:title.contravention'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:text.total'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.PCNByReason}
                    tableRow={<TablePCNByReasons key={11} dataPCNByReason={groupPCNByReason} />}
                />
            );
        } else if (chart == ReportType.evDailyVisit) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: t('reportPage:text.dailyEVCount'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.dailyVisit}
                    tableRow={<TableRowsDailyEVVisits key={2} dailyVisitsStatistic={dailyVisitsStatistic} />}
                />
            );
        } else if (chart == ReportType.evAverageLength) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.rapidHours'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.fastHours'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:text.normalHours'),
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.evAverageLength}
                    tableRow={<TableRowsEVAverageLength key={5} capacityAnalysis={capacityAnalysis.dailyData} />}
                />
            );
        } else if (chart === ReportType.grossRevenueBySTs) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: 'Cashless App',
                            alight: 'center',
                        },
                        {
                            label: 'Kiosk',
                            alight: 'center',
                        },
                        {
                            label: 'Prebook',
                            alight: 'center',
                        },
                        {
                            label: 'QR Web Kiosk',
                            alight: 'center',
                        },
                    ]}
                    reportType={ReportType.grossRevenueBySTs}
                    tableRow={<TableRowsGrossRevenue key={2} grossRevenueStatistic={grossRevenueStatistic} />}
                />
            );
        } else if (chart === ReportType.activeLongTermBooking) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: t('dashboard:title.numberOfLTBookings'),
                            alight: 'center',
                        },
                        // {
                        //     label: t('dashboard:title.totalRevenue'),
                        //     alight: 'center',
                        // },
                    ]}
                    reportType={ReportType.activeLongTermBooking}
                    tableRow={
                        <TableRowsActiveLongTerm key={2} grossRevenueByTypeStatistic={grossRevenueByTypeStatistic} />
                    }
                />
            );
        } else if (chart === ReportType.grossRevenueByLTs) {
            return (
                <MyTable
                    tableHead={[
                        {
                            label: t('reportPage:title.Date'),
                            alight: 'center',
                        },
                        {
                            label: 'Booking amount',
                            alight: 'center',
                        },
                        // {
                        //     label: t('dashboard:title.totalRevenue'),
                        //     alight: 'center',
                        // },
                    ]}
                    reportType={ReportType.grossRevenueByLTs}
                    tableRow={<TableRowsActiveLTs key={2} grossRevenueByLTStatistic={grossRevenueByLTStatistic} />}
                />
            );
        }
    };

    const listTabs = [
        {
            index: 0,
            panel: getPanel(state.reportType),
            label: 'graph',
            icon: <ChartIcon />,
        },
        {
            index: 1,
            panel: getTableRows(state.reportType),
            label: 'details',
            icon: <DetailIcon />,
        },
    ];
    return { ...state, setState, getPanel, getTableRows, listTabs };
}
