import { Button, Grid, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import LoadingCircular from '../../../../../components/LoadingCircular';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import useModal from '../../../../../components/modal/useModal';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { shopController } from '../../../../../controllers';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import useMedia from '../../../../../hooks/useMedia';
import useTenants, { TenantContext } from '../../../../../hooks/useTenants';
import { TenantWithAllocate } from '../../../../../models/tenant/Tenant';
import MyTable, { TableHeadContent } from '../../../../MyTable';
import IconSearch from '../../../components/svg/IconSearch';
import TenantModal from '../TenantModal';
import ModalAddTenant, { FormData } from './ModalAddTenant';
import TableRowTenantsInfo from './TableRowTenantsInfo';
import { AllocatedPermitType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { LocationsContext } from '../../../../../hooks/useLocations';

export default function TenantsTab() {
    const { t } = useContext(LanguageContext);
    const tenantData = useTenants();
    const {
        getTenant,
        setTenantFilterState,
        tenantPaging,
        tenantFilterState,
        handleResetDataTenant,
        isLoadingTenant,
        setTenantPaging,
    } = tenantData;

    const { isExtraSmallXs, isMediumMD } = useMedia();
    const [tenantUpsert, setTenantUpsert] = useState<FormData>({} as any);
    const [idDelete, setIdDelete] = useState<number>(0);
    const [tenantWithAllocate, setTenantWithAllocate] = useState<TenantWithAllocate[]>([]);

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setTenantFilterState((prev) => ({ ...prev, page: value }));
    };

    const { zoneDataState, locationSelect, getLocationDetail } = useContext(LocationsContext);
    const { zonesSelect } = zoneDataState;
    const modalTenant = useModal();
    const stateDialog = useModal();

    const handleDelete = (idClient: number) => {
        setIdDelete(idClient);
        stateDialog.handleOpen();
    };

    const handleOpenModel = () => {
        const tenantFormData: FormData = {
            email: '',
            managerName: '',
            phoneNumber: '',
            tenantName: '',
            maxPermits: 0,
            minVal: 1,
            clientId: 0,
            bayInfo: '',
            isEditBayInfo: true,
            isLockScreenPIN: false,
            isEnableITicketLite: false,
            pin: '',
        };

        setTenantUpsert(tenantFormData);
        modalTenant.handleOpen();
    };

    const handleEditModel = (tenant: TenantWithAllocate) => {
        //for migration permit with amount 0
        tenant.allocatedTypes?.forEach((at) => {
            if (at.allocatedPermitType === AllocatedPermitType.Once) at.amount = 1;
            if ([AllocatedPermitType.Indefinite, AllocatedPermitType.Dynamic].includes(at.allocatedPermitType)) {
                at.isValid = false;
            } else {
                at.isValid = true;
            }
        });

        const tenantFormData: FormData = {
            email: tenant.email,
            managerName: tenant.managerName,
            phoneNumber: tenant.phoneNumber ?? '',
            tenantName: tenant.tenantName,
            maxPermits: tenant.maxAllocatedAmount ?? 0,
            clientId: tenant.clientId ?? 0,
            zoneIds: tenant.allocatedPermits?.map((item) => item.zoneId!) ?? [],
            bayInfo: tenant.bayInfo ?? '',
            isEditBayInfo: !!tenant.isEditBayInfo,
            allocatedTypes: tenant.allocatedTypes,
            isLockScreenPIN: tenant.isLockScreenPIN,
            isEnableITicketLite: tenant.isEnableITicketLite ?? false,
            pin: tenant.pin ?? '',
        };
        setTenantUpsert(tenantFormData);
        modalTenant.handleOpen();
    };

    const onDelete = () => {
        shopController
            .delete(idDelete.toString())
            .then((res) => {
                //close popup
                stateDialog.handleClose();
                //refresh tenant
                handleResetDataTenant();
                //refresh zone
                getLocationDetail();
                //popup success
                toast.success(t('notification:changesSaved'));
            })
            .catch((err) => {
                if (err.response.status !== 500) toast.error(err.response.data.value);
            });
    };

    const handleSearch = (e: any) => {
        setTenantFilterState((prev) => ({ ...prev, page: 1, searchValue: e.target.value }));
    };

    const largeHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'left',
        },
        {
            label: t('companies:tenants.managerName'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.email'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.usedPermits'),
            alight: 'center',
        },
        // {
        //     label: 'Lock Screen PIN',
        //     alight: 'center',
        // },
        {
            label: 'Lock Screen PIN',
            alight: 'center',
        },
        {
            label: '',
            alight: 'center',
        },
    ];
    const smallHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'left',
        },
        {
            label: t('companies:tenants.usedPermits'),
            alight: 'center',
        },
        {
            label: '',
            alight: 'center',
        },
    ];

    return (
        <TenantContext.Provider value={tenantData}>
            <Grid item container padding={'16px 16px 40px 16px'} gap={'16px'}>
                <TenantModal
                    key={1}
                    open={modalTenant.open}
                    handleOpen={handleOpenModel}
                    handleClose={modalTenant.handleClose}
                    modalContent={
                        <ModalAddTenant
                            tenant={tenantUpsert}
                            handleClose={modalTenant.handleClose}
                            tenantWithAllocate={tenantWithAllocate}
                            isEdit={!!tenantUpsert.clientId}
                        />
                    }
                />
                <ConfirmDialog
                    open={stateDialog.open}
                    title={t('action:confirm')}
                    content={t('companies:tenants.deleteTenantConfirm')}
                    onClose={stateDialog.handleClose}
                    onConfirm={onDelete}
                />
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    xs={12}
                    gap={2}
                >
                    <TextField
                        fullWidth
                        disabled={locationSelect && zonesSelect && zonesSelect.length <= 0}
                        placeholder={t('companies:input.searchByNameEmail')}
                        defaultValue={tenantFilterState.searchValue}
                        onChange={_.debounce((value) => handleSearch(value), 1000)}
                        inputProps={{
                            maxLength: 50,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <IconSearch />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ minWidth: { xs: '120px', md: '200px' } }}
                        disabled={(locationSelect && zonesSelect && zonesSelect.length <= 0) || isLoadingTenant}
                        onClick={handleOpenModel}
                    >
                        {t('companies:tenants.addTenant')}
                    </Button>
                </Grid>

                {isLoadingTenant ? (
                    <LoadingCircular />
                ) : tenantPaging.data && tenantPaging.data.length > 0 ? (
                    <>
                        <Grid item xs={12} marginTop={'-8px'}>
                            <MyTable
                                tableHead={isExtraSmallXs || isMediumMD ? smallHead : largeHead}
                                tableRow={
                                    <TableRowTenantsInfo
                                        tenantWithAllocates={tenantPaging.data}
                                        editFunction={handleEditModel}
                                        deleteFunction={handleDelete}
                                        setTenantPaging={setTenantPaging}
                                        tenantPagination={tenantPaging}
                                        setTenantWithAllocate={setTenantWithAllocate}
                                        tenantWithAllocate={tenantWithAllocate}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item display={'flex'} justifyContent="center" xs={12}>
                            <Pagination
                                count={tenantPaging.totalPage}
                                page={tenantPaging.page}
                                onChange={handleChangePagination}
                                variant="outlined"
                                shape="rounded"
                                // sx={{ mt: 3 }}
                            />{' '}
                        </Grid>
                    </>
                ) : tenantPaging.data.length === 0 &&
                  tenantFilterState.searchValue !== '' &&
                  zonesSelect?.length !== 0 ? (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                ) : tenantPaging.data.length === 0 &&
                  tenantFilterState.searchValue === '' &&
                  zonesSelect?.length === 0 ? (
                    <NoDataToDisplay text={t('companies:tenants.youMustHaveAtLeastOneZoneToAddTenant')} />
                ) : (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                )}
            </Grid>
        </TenantContext.Provider>
    );
}
